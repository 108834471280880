// Fix for IE10 and Windows Mobile
if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
    var msViewportStyle = document.createElement('style');
    msViewportStyle.appendChild(
        document.createTextNode(
            '@-ms-viewport{width:auto!important}'
        )
    );
    document.querySelector('head').appendChild(msViewportStyle);
}

// Animated scrolling
$('a[rel="scroll"]').click(function (event) {
    event.preventDefault();
    var scrolloffset = $('.navbar').outerHeight() + 20;
    var hash = $(this).attr('href');
    $("html, body").animate({scrollTop: $(hash).offset().top - scrolloffset}, 1200);
});

$(document).ready(function () {
    // Tooltip
    $('[data-toggle="tooltip"]').tooltip();

    // FitVids
    if ($.isFunction($.fn.fitVids)) {
        $('.fitvids').fitVids();
    }

    // Fancybox
    $(".fancybox").fancybox({
        'loop': false,
        helpers: {
            title: {
                type: 'inside'
            }
        }
    });
});

$('.header-slider').slick({
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 500
});
